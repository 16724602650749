<template>
  <div v-if="myType && mounted" class="container cover">
    <Clips style="left: -20px; transform: scale(-1,1);" />
    <div class="img-container" style="height: 0;">
      <img src="/icon/clip.png" alt="클립" id="clip-img" />
    </div>
    <img :src="`/character/${myType}.png`" :alt="result[myType].title" class="result" />
    <!-- 캐릭터 부제목 -->
    <section class="sub-title">
      <div class="inner-white">
        <div class="dot"></div>
        <span>{{result[myType].subTitle}}</span>
        <div class="dot"></div>
      </div>
    </section>
    <section class="post-it">
      <h2 class="post-it-header">특징 및 장점</h2>
      <span v-html="result[myType].description"></span>
      <div class="fold"></div>
    </section>
    <section class="post-it">
      <h2 class="post-it-header">주의점</h2>
      <span v-html="result[myType].warning"></span>
      <div class="fold"></div>
    </section>
    <section class="teamwork">
      <section v-if="myGoodPartner" class="partner">
        <h2 class="sub-title">
          <div class="inner-white">
            <span class="blue">환상</span>
            <span>의 팀워크</span>
          </div>
        </h2>
        <img
          :src="`/teamwork/${myGoodPartner}.png`"
          :alt="result[myGoodPartner].title"
          class="result-partner"
        />
      </section>
      <section v-if="myBadPartner" class="partner">
        <h2 class="sub-title">
          <div class="inner-white">
            <span class="red">환장</span>
            <span>의 팀워크</span>
          </div>
        </h2>
        <img
          :src="`/teamwork/${myBadPartner}.png`"
          :alt="result[myBadPartner].title"
          class="result-partner"
        />
      </section>
    </section>
    <section class="partner">
      <h3 class="share-title">결과 공유하기</h3>
      <div class="share-box">
        <a
          class="icon"
          target="_blank"
          onclick="window.open(this.href,'targetWindow',`toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=600px, height=800px `);return false;"
          :href="`https://www.facebook.com/sharer/sharer.php?u=https://kkangbti-ns.com/result/${myType}`"
        >
          <picture>
            <img src="/icon/facebook.png" alt="페이스북 공유하기" />
          </picture>
        </a>
        <a
          class="icon"
          target="_blank"
          onclick="window.open(this.href,'targetWindow',`toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600px,height=800px`); return false;"
          :href="`https://twitter.com/intent/tweet?url=https://kkangbti-ns.com/result/${myType}`"
        >
          <picture>
            <img src="/icon/twitter.png" alt="트위터 공유하기" :style="{'border-radius' : '0px'}" />
          </picture>
        </a>
        <a class="icon" @click="kakao" id="kakao-link-btn">
          <picture>
            <img src="/icon/kakao.png" alt="카카오톡 공유하기" />
          </picture>
        </a>
        <a class="icon" @click.prevent="copyURL">
          <picture>
            <img src="/icon/link.png" alt="링크 공유하기" />
          </picture>
        </a>
      </div>
    </section>
    <button class="retry" @click="goHome">테스트 다시하기</button>
  </div>
</template>
<script>
import copy from "copy-to-clipboard";
import Clips from "@/components/Clips.vue";
export default {
  name: "Result",
  components: {
    Clips
  },
  data() {
    return {
      myType: null,
      mounted: false,
      result: {
        새우깡: {
          title: "사회생활만렙 고속승진 새우깡",
          subTitle: `"엄마!! 나 승진했어~"`,
          description:
            "직장 내에서 팀을 위해 열정으로 불태우는 깡을 소유한 당신. 전형적인 리더의 자질을 갖추고 있군요! 혹시 이미 상사의 사랑을 독차지하고 있지 않나요?<br/>오랜 시간 동안 사랑받아 온 새우깡처럼 팀 내에서 꾸준히 존재감을 발휘하고 있는 당신! 팀원들이 필요할 때에 나타나주는 당신은 센스쟁이! 타고난 센스를 적극 활용하여 업무 할 때 효율의 끝을 보여주는 당신은 “손이가요, 손이가~”<br/>팀원들을 홀리는 새우깡 같은 매력의 소유자. 때로는 옳다고 믿는 것은 확실히 밀고 나갈 줄 아는 당신은 팀을 좋은 성과로 이끌어 동료들에 비해 다소 빠른 기간 내에 승진할 수도 있겠네요.",
          warning:
            "팀 내에 의견이 충돌이 있을 때에는 경청의 자세로 팀원들의 의견을 들어주세요. 특히 회의 중 본인만 말하고 있는 것은 아닌지 주의해주세요. 진중한 소통으로 팀원들에게 다가가면 문제를 더욱 신속하고 원만하게 해결할 수 있겠네요!",
          good: "옥수수깡",
          bad: "매운새우깡"
        },
        쌀새우깡: {
          title: "열쩡 열쩡 열쩡 센스쟁이 쌀새우깡 사원",
          subTitle: `"딸기가 회사에서 잘리면? 딸기시럽 푸하하하"`,
          description:
            "팀을 위해서라면 솔선수범하는 자세로 임하는 당신! 혹시 사내에서 인싸로 통하지 않나요? <br/>당신과 함께라면 어떤 것도 두렵지 않아~ 팀원들의 든든한 버팀목 같은 존재네요! 뿐만 아니라, 나의 욕심보다 팀원들의 편의를 우선으로 생각하는 당신은 담백한 매력의 소유자인 쌀새우깡의 모습과도 많이 닮아 있네요. 예기치 못한 상황에서도 타고난 센스를 발휘하여 대처하는 모습 또한 보여주기도 하군요! <br/>가끔은 팀원들을 위해 아재개그도 준비하는 재치 있는 모습에 팀원들뿐만 아니라 팀장님의 사랑 또한 독차지하고 있군요. 당신이 있는 한 팀의 분위기는 걱정이 필요 없을 것 같네요!",
          warning:
            "직장 생활 중, 자신보다 남을 더 생각하여 속앓이를 할 수도 있겠군요. 때로는 나 자신을 그 누구보다 우선하는 습관을 길러보세요! 그거 알죠? 나의 행복은 내가 챙겨야 하는 거~?",
          good: "감자깡",
          bad: "매운쌀새우깡"
        },
        매운쌀새우깡: {
          title: "일과 연애하는 든든한 인싸 사수 매운+쌀새우깡",
          subTitle: `"나만 믿어 엣헴"`,
          description:
            "물음표 살인마인 인턴이 들어와도 자상하게 알려주는 당신은 직장 내 든든한 사수!<br/>의외로, 원칙주의의 성향 또한 소유하고 있는 당신은 To Do List를 작성하며 스케줄대로 움직이는 체계적 모습도 가지고 있네요. 일을 할 때만큼은 매운맛이지만 평소 주위사람들도 잘 챙기는 담백한 매력을 가진 당신. 이처럼 자신만의 확고한 기준을 가지고 임하는 모습이 반전 매력의 소유자인 ‘매운+쌀새우깡’과 비슷하네요.<br/>아랫사람에게는 친절하면서도 일할 때 드러나는 시크한 모습 덕분에 신입사원들의 사랑을 독차지하는 당신은 만인의 존경의 대상! 때문에, 동료들의 귀여운 질투를 불러일으킬 수도 있겠네요!",
          warning:
            "계획에 차질이 생겼을 때에는 당황하지 말고, 다른 방안들을 차분히 생각해 보세요. 평소에 유연하게 사고하는 습관을 기르다 보면, 예기치 못한 상황이 일어나도 융통성 있게 대처할 수 있을 거예요!",
          good: "양파깡",
          bad: "쌀새우깡"
        },
        매운새우깡: {
          title: "실적왕은 다 내꺼 완벽주의 매운새우깡",
          subTitle: `"드루와 드루와 내가 다 부셔주겠어"`,
          description:
            "진정한 워커홀릭! 일할 때는 매운맛! 회사 내에서 한 깡다구하는 당신! 어떠한 일이든 잘하기 위해 최선을 다하고 대충하는 자신을 용납하지 않죠! 자기 자신에 대한 믿음이 강해 추진력이 뛰어나고 엄격한 자기만의 기준을 갖고 있어 그 틀에서 크게 벗어나지 않고자 해요. 진행하는 일마다 좋은 성과를 보여주는 진정한 성과주의 유형이에요.<br/>후배들은 조금 어려워할 수도 있는 선배이지만, 선배들에게만큼은 이쁨 받는 팀의 든든한 실력자! 어떤 일을 맡아도 2인분 이상하는 당신은 모든 팀의 워너비사원!!",
          warning:
            "때때로 혼자 열정만 앞서고 자기 자신에 대한 믿음이 과하게 표출될 때, 가끔 다른 사원들로부터 미움을 받게 될 수 있어요. 동료들의 의견도 잘 듣고 한 팀으로써 협업하여 팀워크까지 갖추게 된다면 더 발전하는 매새깡이 될 거에요! ^~^",
          good: "고구마깡",
          bad: "새우깡"
        },
        감자깡: {
          title: "그대들의 우렁각시 감자깡 사원",
          subTitle: `"좋은게 좋은거야 나는야 예스맨”`,
          description:
            "책임감 빼면 시체! 이래봬도 저 깡 있는 사람입니다!(빠직!)<br/> 자신에게 주어진 일은 어떠한 일이 있어도 단단한 감자처럼 묵묵하게 끝까지 해내고 맙니다. 다른 사람들의 말을 귀 기울일 줄 알며 주변사람들을 잘 챙겨서 평소 많은 이들에게 인기를 얻는 당신! 당신이 매력있는 사람인지 겪어보지 않으면 몰라요. 계속 계속 집어먹게 되는 감자깡처럼 계속 찾게 될 거예요!<br/>작은 것에도 감사할 줄 아는 당신! 감자 같은 우직한 면이 있어 선배들은 물론 동기, 후배들에게도 인기를 얻는 당신은 모든 신입사원의 희망하는 사수 유형 1순위!!",
          warning:
            "회사 생활을 하다 보면 종종 자신의 의견을 명확하게 표현해야 할 때가 있습니다. 다른 이의 의견을 먼저 들어주고 배려하는 모습도 좋지만 일방적인 관심과 배려는 자기 자신을 지치게 할 수 있습니다. <br/>때로는 자신의 주장도 논리적으로 내세울 줄 알고 다른 이들에게도 존중받을 수 있게 된다면 조금 더 우직한 감자깡이 될 거예요! ^__^",
          good: "쌀새우깡",
          bad: "양파깡"
        },
        양파깡: {
          title: "까도까도 미담뿐 이시대의 모범사원 양파깡 ",
          subTitle: `“다 팀장님이 잘 이끌어 주신 덕분입니다”`,
          description:
            "엄친깡!!(엄마 친구 양파깡) 우리 팀의 숨은 에이스!!<br/>회사 내에서 먼저 나서기 보다는 그저 무탈하게 시간을 보내고자 하며 자신이 해야 할 일은 남이 시키지 않아도 알아서 척척 해내는 당신! 단체생활이 처음에는 어색할 수 있지만 금방 적응합니다. 적응이 완료된 당신은 어느 순간 이미 회사 내에서 단짠매력의 소유자. 남들 모르게 조용히 성과를 내는 엘리트 사원이 되어있습니다!<br/>후배, 동기, 선배를 막론하며 모두에게 인정을 받는 당신! 평소 조용히 회사생활을 하지만, 할 때는 제대로 하는 당신! 회사 임원들의 뽑고 싶은 입사 합격자 유형 1순위!!",
          warning:
            "가끔은 자신이 처한 상황이나 자신의 이야기를 주위 사람들에게 해줄 필요가 있어요. 혼자서 묵묵히 모든 걸 해내려고 하는 마음이 때로는 당신에게 큰 압박감을 주고 지치게 된답니다.<br/>때로는 주변인들의 도움을 청하고 받을 수 있는 용기가 있다면 지금보다 더 업무성과가 뛰어난 양파깡이 될 거예요!",
          good: "매운쌀새우깡",
          bad: "감자깡"
        },
        옥수수깡: {
          title: "지연씨 아바라 맞죠? 츤데레 옥수수깡 사원",
          subTitle: `“제 꺼 사는 김에 지연씨 것도 샀어요 ㅎㅎ”`,
          description:
            "옥수수깡처럼 고소하고 중독적인 매력의 소유자인 당신은 약간 차가움과 따뜻함을 넘나드는 목욕탕 같은 사람이랄까…?<br/>독립적인 성향이 강하지만 묵묵히 자신의 일을 열심히 하며 어떤 일이던 평균 이상의 성과를 내는 유형이에요. 팀에서 없어서는 안 될 감초 같은 존재예요. 직관력이 뛰어나 일을 풀어가는 능력이 상당하고 업무 센스가 높아 어려운 일도 이 사람과 함께하면 쉽게 뚝딱 끝나버리는 기적이 일어날 거예요!<br/>업무 센스뿐 아니라 회사 내에서도 종종 센스를 발휘하는데 조용한 줄 알았던 이 사람 직원들의 커피 취향은 어떻게 알고 사 오고 회식 때 내 앞에 고기 놔주는 거 뭐야 뭐야! 사소한 센스가 몸에 배어있는 사내 서윗사원이에요. 근데 본인은 잘 몰라요. 그냥 당연한 줄 알고 있어요.",
          warning:
            "그러나 이러한 모습 때문에 답답해 보일 때가 있어요. 오히려 사람들이 더 어려워할 수도 있어요. 껍질을 벗겨야 옥수수가 나오듯이 본인의 속마음이나 매력을 팀원이나 다른 사람들에게 더 표현해보면 좋겠어요.",
          good: "새우깡",
          bad: "고구마깡"
        },
        고구마깡: {
          title: "안녕히계세요 여러분~ 캍퇴좌 고구마깡",
          subTitle: `"저는 이 세상에 모든 굴레와 속박을 벗어던지고 행복을 찾아 떠납니다"`,
          description:
            "고구마깡처럼 묵직하지만 내면에 달달하고 꿀 떨어지는 매력을 소유한 당신은 듬직한 회사의 기둥 같은 사람이랄까…?<br/>혼자 있는 걸 좋아하는 당신은 개인 시간을 존중받기를 원하며 팀 내에서도 묵묵한 유형이에요. 좋은 사수의 가르침에 따라 업무능력은 상당히 빠르고 간결해요. 실수를 용납하지 않는 완벽주의 성향이 강해서 맡은 업무에 책임감이 강하며 덕분에 팀 내에서 해결하기 힘든 일들을 누구보다 쉽게 처리해내요.<br/>인간미 없어 보이지만 사실 집에서는 인간미 철철! 워라벨을 상당히 중요하게 여겨요. 퇴근 후 혼자만의 시간을 누구보다 잘 활용하며, 자신을 잘 돌봐주고 사랑해 줄줄 아는 사람이에요. 마음이 맞는 친구들과의 모임에서는 거의 개그맨 뺨치는 리액션과 입담을 선보여주죠. ",
          warning:
            "너무 완벽하지 않아도 괜찮으니 마음을 조금 열어서 회사 사람들에게 당신의 내면에 숨어있는 달달하고 꿀 떨어지는 매력을 더욱 보여준다면 고구마깡처럼 더욱 더 달콤한 회사 생활이 될 거예요.",
          good: "매운새우깡",
          bad: "옥수수깡"
        }
      }
    };
  },
  metaInfo() {
    return {
      title: this.result[this.myType].title,
      description: this.result[this.myType].description,
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.result[this.myType].title
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `https://kkangbti-ns.com/character/${this.myType}.png`
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.result[this.myType].description
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `https://kkangbti-ns.com/result/${this.myType}`
        }
      ]
    };
  },
  computed: {
    myGoodPartner() {
      return this.result[this.myType].good;
    },
    myBadPartner() {
      return this.result[this.myType].bad;
    }
  },
  created() {
    this.myType = this.$route.params.id;
    if (this.result[this.myType] === undefined) {
      this.$router.push({
        name: "Home"
      });
    }
  },
  mounted() {
    this.mounted = true;
    document.body.style.backgroundColor = "#ffe9e6";
  },
  methods: {
    goHome() {
      this.$router.push({
        name: "Home"
      });
    },
    kakao() {
      Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: this.result[this.myType].title,
          description: this.result[this.myType].description,
          imageUrl: `https://kkangbti-ns.com/character/${this.myType}.png`,
          link: {
            mobileWebUrl: `https://kkangbti-ns.com/result/${this.myType}`,
            webUrl: `https://kkangbti-ns.com/result/${this.myType}`
          }
        },
        buttons: [
          {
            title: "테스트 시작!",
            link: {
              mobileWebUrl: `https://kkangbti-ns.com/result/${this.myType}`,
              webUrl: `https://kkangbti-ns.com/result/${this.myType}`
            }
          }
        ]
      });
    },
    copyURL() {
      copy(`https://kkangbti-ns.com/result/${this.myType}`);
      window.alert("주소를 복사했습니다.");
    }
  }
};
</script>
<style lang="scss" scoped>
img.result {
  margin-bottom: 30px;
}
section.sub-title {
  border-radius: 5px;
  border: 3px solid #624c34;
  width: 100%;
  max-width: 320px;
  margin-bottom: 25px;
}
section.teamwork {
  display: flex;
  gap: 30px;
  margin-bottom: 60px;
}
h2.sub-title {
  border-radius: 5px;
  border: 3px solid #624c34;
  width: 100%;
  max-width: 320px;
  margin-bottom: 25px;
  .inner-white {
    padding: 10px;
    justify-content: center;
    & > span {
      font-family: "ONE-Mobile-POP";
      font-weight: 400;
      font-size: inherit;
      margin-right: -3px;
      &.blue {
        color: #051edd;
      }
      &.red {
        color: #e00911;
      }
    }
  }
}
h3.share-title {
  font-size: 16px;
  color: #624c34;
  text-align: center;
  margin-bottom: 20px;
}
div.share-box {
  display: flex;
  gap: 20px;
  margin-bottom: 60px;
  img {
    width: 48px;
  }
}
button.retry {
  background-color: #ffffff;
  font-family: "ONE-Mobile-POP";
  font-size: 30px;
  color: #624c34;
  padding: 20px 40px;
  border-radius: 15px;
}
.inner-white {
  display: flex;
  align-items: center;
  color: #624c34;
  border-radius: 3px;
  padding: 20px;
  font-size: 20px;
  width: 100%;
  text-align: center;
  justify-content: space-between;
  background-color: #ffffff;
  & > span {
    font-family: "ONE-Mobile-POP";
    display: block;
    max-width: 200px;
    word-break: keep-all;
  }
  .dot {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #624c34;
  }
}
.img-container {
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  .question-mark {
    right: 0px;
    position: absolute;
    top: 12px;
  }
  #clip-img {
    position: relative;
    left: 10px;
  }
}
</style>